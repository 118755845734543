import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';


const UnitsOverview = ({ projectId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [units, setUnits] = useState([]);

    useEffect(() => {
        const fetchUnitData = async () => {
            try {
                let { data: units, error: unitsError } = await supabase
                    .from('units')
                    .select('id, name, picture')
                    .eq('client_id', projectId);

                if (unitsError) throw unitsError;
                if (!units.length) throw new Error('No unit found for the provided project id');

                for (const unit of units) {
                    let { data: unitKpis, error: kpisError } = await supabase
                        .from('kpis')
                        .select(`
                            id,
                            created_at,
                            client_id,
                            unit_id,
                            value,
                            req_kpi_id,
                            req_kpi (
                                id,
                                created_at,
                                unit_id,
                                oid,
                                name,
                                measurement_unit,
                                updated_at,
                                kpi_type,
                                denominator
                            )
                            `)
                            .eq('client_id', projectId)
                            .eq('unit_id', unit.id)
                            .order('created_at', { ascending: false });

                    if (kpisError) throw kpisError;

                    // Filter the results on the client-side
                    unitKpis = unitKpis.filter(kpi => kpi.req_kpi && kpi.req_kpi.kpi_type !== 'alarm');

                    // Get the latest value for each req_kpi type
                    const latestKpis = {};
                    for (const kpi of unitKpis) {
                        if (!latestKpis[kpi.req_kpi.name] || new Date(kpi.created_at) > new Date(latestKpis[kpi.req_kpi.name].created_at)) {
                            latestKpis[kpi.req_kpi.name] = kpi;
                        }
                    }

                    unit.latestKpis = Object.values(latestKpis);

                    // Divide the kpi.value with req_kpi.denominator and sort the KPIs alphabetically
                    unit.latestKpis.forEach(kpi => {
                        kpi.value = kpi.value / kpi.req_kpi.denominator;
                    });
                    unit.latestKpis.sort((a, b) => a.req_kpi.name.localeCompare(b.req_kpi.name));
                }

                setUnits(units);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUnitData();
    }, [projectId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h4>Units</h4>
            {units.map((unit, index) => (
                <div key={index} style={{ display: 'flex', margin: '10px', border: '1px solid #ccc' }}>
                    <div style={{ flex: '1 0 20%', padding: '10px' }}>
                        <h5>Unit: {unit.name}</h5>
                        <p>Latest KPIs:</p>
                        {unit.latestKpis.map((kpi, index) => (
                            <p key={index}>{kpi.req_kpi.name}: {kpi.value}</p>
                        ))}
                    </div>
                    <img src={`/assets/${unit.picture}`} alt={unit.name} style={{ width: '350px', height: '500px', marginRight: '10px' }} />
                </div>
            ))}
        </div>
    );
};

export default UnitsOverview;