// src/components/SignOut.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.signOut();
    navigate('/');
  }, [navigate]);

  return null;
};

export default SignOut;