import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { supabase } from '../../supabaseClient';

const Authenticated = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const location = useLocation();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error; // Handle potential errors
        setUser(session?.user);
      } catch (error) {
        console.error('Error fetching session:', error.message);
      } finally {
        setLoading(false); // Ensure loading is set to false after the check
      }
    };

    checkUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator or return null to wait silently
  }

  return user ? children : <Navigate to="/" state={{ from: location }} />;
};

export default Authenticated;