// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/common/Login';
import Dashboard from './components/dashboard/Dashboard';
import ProjectDetails from './components/units/ProjectDetails';
import HVACDetails from './components/units/HVACDetails';
import Authenticated from './components/common/Authenticated';
import SignOut from './components/common/SignOut';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="/dashboard" element={
          <Authenticated>
            <Dashboard />
          </Authenticated>
        } />
        <Route path="/project/:projectId" element={
          <Authenticated>
            <ProjectDetails />
          </Authenticated>
        } />
        <Route path="/hvac/:unitId" element={
          <Authenticated>
            <HVACDetails />
          </Authenticated>
        } />
      </Routes>
    </Router>
  );
}

export default App;