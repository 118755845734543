// src/components/DeviceOverview.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { FaFan } from 'react-icons/fa'; // Import the fan icon

const DeviceOverview = ({ unitId, kpiIds }) => {
  const [kpis, setKpis] = useState([]);

  useEffect(() => {
    const fetchKpis = async () => {
      const { data, error } = await supabase
        .from('kpis')
        .select(`
          id,
          created_at,
          client_id,
          unit_id,
          value,
          req_kpi_id,
          req_kpi (
            id,
            created_at,
            unit_id,
            oid,
            name,
            measurement_unit,
            updated_at,
            kpi_type,
            denominator
          )
        `)
        .in('req_kpi_id', kpiIds)
        .eq('unit_id', unitId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching KPIs:', error);
      } else {
        // Group by req_kpi_id and take only the first (latest) record of each group
        const latestKpis = data.reduce((acc, kpi) => {
          if (!acc[kpi.req_kpi_id] || acc[kpi.req_kpi_id].created_at < kpi.created_at) {
            acc[kpi.req_kpi_id] = kpi;
          }
          return acc;
        }, {});

        setKpis(Object.values(latestKpis));
      }
    };

    fetchKpis();
  }, [unitId, kpiIds]);

  return (
    <div>
        {kpis.map((kpi, index) => (
          <div key={index} style={{ flex: '0 0 50%' }}>
            <p>
              {kpi.req_kpi.name}: {kpi.value / kpi.req_kpi.denominator}
              {kpi.req_kpi.name.toLowerCase().includes('speed') && (
                <FaFan style={{
                    animation: `spin ${kpi.value != 0 ? (Math.abs(kpi.value / kpi.req_kpi.denominator) + 1) / 50 : 10000000}s linear infinite`,
                    marginLeft: '10px',
                    fontSize: '3em'
                  }} />
              )}
            </p>
          </div>
        ))}
      </div>
  );
};

export default DeviceOverview;