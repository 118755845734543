// src/components/GraphWidget.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const GraphWidget = ({ unitId, kpiIds }) => {
  const [timeframe, setTimeframe] = useState('day');
  const [graphData, setGraphData] = useState([]);
  const [kpiIdNames, setkpiIdNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  // Function to generate unique colors for each KPI line
  const getColor = (index) => {
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d3d3d3']; // Add more colors as needed
    return colors[index % colors.length];
  };

  const getStartDateForTimeframe = (timeframe) => {
    const now = new Date();
    switch (timeframe) {
      case 'day':
        now.setHours(now.getHours() - 24);
        break;
      case 'week':
        now.setDate(now.getDate() - 7);
        break;
      case 'month':
        now.setMonth(now.getMonth() - 1);
        break;
      default:
    }
    return now.toISOString();
  };

  async function fetchKpiNames() {
      const newKpiIdNames = {};

      for (const kpiId of kpiIds) {
        const { data, error } = await supabase
          .from('req_kpi')
          .select('name')
          .eq('id', kpiId)
          .single();

        if (error) {
          console.error('Error fetching data: ', error);
          continue; // If there's an error, skip this iteration
        }

        newKpiIdNames[kpiId] = data.name;

      }
      setkpiIdNames(newKpiIdNames);
    }


  function reorganizeData(inputArray) {
      const organizedData = {};

      inputArray.forEach(item => {
          // Extract date up to minutes
          const dateKey = item.created_at.substring(0, 16);

          // Initialize a new object for this date if it doesn't exist
          if (!organizedData[dateKey]) {
              organizedData[dateKey] = { "created_at": dateKey };
          }
          console.log(item)
          // Add the KPI id and value
          organizedData[dateKey][item.req_kpi_id.toString()] = item.value/item.req_kpi.denominator;
      });

      // Convert to array of objects
      return Object.values(organizedData);
  }



  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      const startDate = getStartDateForTimeframe(timeframe);
  
      // Fetch data for all KPI IDs
      const allDataPromises = kpiIds.map((kpiId) => {
        return supabase
          .from('kpis')
          .select(`
              id,
              created_at,
              client_id,
              unit_id,
              value,
              req_kpi_id,
              req_kpi (
                id,
                created_at,
                unit_id,
                oid,
                name,
                measurement_unit,
                updated_at,
                kpi_type,
                denominator
              )
            `)
          .eq('unit_id', unitId)
          .eq('req_kpi_id', kpiId)
          .gte('created_at', startDate);
      });
  
      // Wait for all requests to complete
      const allDataResponses = await Promise.all(allDataPromises);
  
      // Check for errors and extract data
      const allData = [];
      allDataResponses.forEach(({ data, error }) => {
        if (error) {
          console.error('Error fetching data:', error);
        } else {
          allData.push(...data);
        }
      });
  
      // Process and set the graph data
      const processedData = reorganizeData(allData);
      setGraphData(processedData);
      fetchKpiNames();
      setIsLoading(false); // End loading
    };
  
    fetchData();
  }, [unitId, kpiIds, timeframe]);
  if (isLoading) {
    return <div>Loading...</div>; // Show a loading message or a spinner
  }


  const oneDayInMS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const now = new Date();
  const domain = [now.getTime() - oneDayInMS, now.getTime()]; // Last 24 hours
  return (
    <div>
      <div>
        <button onClick={() => setTimeframe('day')}>Day</button>
        <button onClick={() => setTimeframe('week')}>Week</button>
        <button onClick={() => setTimeframe('month')}>Month</button>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="created_at"
            //type="number"
            //domain={domain}
            tickFormatter={(unixTime) => new Date(unixTime).toLocaleTimeString()}
            //ticks={[domain[0], domain[0] + oneDayInMS / 2, domain[1]]} // Start, middle, and end of 24 hours
          />
          <YAxis />
          <Tooltip />
          <Legend />
              {kpiIds.map(i => (
                <Line
                  key={i}
                  type="monotone"
                  dataKey={i}
                  name={kpiIdNames[i]}
                  stroke={getColor(i)}
                  dot={false}
                />
              ))}
        </LineChart>
    </ResponsiveContainer>
    </div>
  );
};

export default GraphWidget;
