// src/components/HVACUnitList.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import '../../App.css';


const HVACUnitList = ({ projectId , onUnitSelect }) => {
  const [units, setUnits] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUnits();
  }, [projectId]);

  const fetchUnits = async () => {
    const { data, error } = await supabase
      .from('units')
      .select('*')
      .eq('client_id', projectId);
  console.log(data)
    if (!error) {
      setUnits(data);
    } else {
      console.error('Error fetching units:', error);
    }
  };

  const handleUnitClick = (unitId) => {
    onUnitSelect(unitId);
  };

  return (
    <div>
      <h2>HVAC Units</h2>
      <ul>
        {units.map((unit) => (
          <li key={unit.id} onClick={() => handleUnitClick(unit.id)}>
            {unit.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HVACUnitList;
