// src/components/ProjectList.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';


const ProjectList = ({ projects }) => {
  const navigate = useNavigate();

  const viewProjectDetails = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  return (
    <div>
      <h2>Projects</h2>
      <div className="project-layout">
        <ul>
          {projects.map((project) => (
            <li key={project.id} onClick={() => viewProjectDetails(project.id)}>
              {project.clientname}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProjectList;
