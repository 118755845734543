import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const AlarmsOverview = ({ projectId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [units, setUnits] = useState([]);

    useEffect(() => {
        const fetchAlarmData = async () => {
            try {
                // Fetch the units using the projectId
                let { data: units, error: unitsError } = await supabase
                    .from('units')
                    .select('id, name')
                    .eq('client_id', projectId);

                if (unitsError) throw unitsError;
                if (!units.length) throw new Error('No unit found for the provided project id');

                for (const unit of units) {
                    let { data: unitKpis, error: kpisError } = await supabase
                        .from('kpis')
                        .select(`
                            id,
                            created_at,
                            client_id,
                            unit_id,
                            value,
                            req_kpi_id,
                            req_kpi (
                                id,
                                created_at,
                                unit_id,
                                oid,
                                name,
                                measurement_unit,
                                updated_at,
                                kpi_type,
                                denominator
                            )
                            `)
                            .eq('client_id', projectId)
                            .eq('unit_id', unit.id)
                            .eq('value', '1')
                            .order('created_at', { ascending: false });

                    if (kpisError) throw kpisError;

                    // Filter the results on the client-side
                    unitKpis = unitKpis.filter(kpi => kpi.req_kpi && kpi.req_kpi.kpi_type === 'alarm');

                    const now = new Date();
                    const oneHourAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

                    const activeAlarms = unitKpis.filter(kpi => new Date(kpi.created_at) >= oneHourAgo);
                    const historicalAlarms = unitKpis.filter(kpi => new Date(kpi.created_at) < oneHourAgo);

                    unit.activeAlarmsCount = activeAlarms.length;
                    unit.historicalAlarmsCount = historicalAlarms.length;
                    unit.activeAlarms = activeAlarms;
                }

                setUnits(units);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAlarmData();
    }, [projectId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h4>Alarms</h4>
            {units.map((unit, index) => (
                <div key={index} style={{ flex: '1 0 20%', margin: '10px', padding: '10px', border: '1px solid #ccc' }}>
                    <h5>Unit: {unit.name}</h5>
                    <p>Active Alarms in last 24 hours: {unit.activeAlarmsCount}</p>
                    <p>Historical Alarms: {unit.historicalAlarmsCount}</p>
                    <p>List of active alarms in last 24 hours:</p>
                    {unit.activeAlarms.map((kpi, index) => (
                        <p key={index}>{kpi.req_kpi.name}: {kpi.value}</p>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default AlarmsOverview;