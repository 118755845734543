// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import ProjectsOverview from '../units/ProjectsOverview';
import { supabase } from '../../supabaseClient';
import ProjectList from '../units/ProjectList';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import '../../App.css';


const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setIsLoading(true);
    const { data: { session } } = await supabase.auth.getSession();
  	const user = session?.user;
    let query = supabase
      .from('projectAssignment')
      .select('projects(*)')
      .eq('user_id', user.id);
    const { data, error } = await query;
    if (!error) {
      setProjects(data.map(item => item.projects));
    } else {
      console.error('Error fetching projects:', error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <aside className="navbar">
        <div>
          <ProjectList projects={projects} />
        </div>
        <div className="signout-link">
              <Link to="/signout">Sign Out</Link>
        </div>
      </aside>
      <main className="main-content">
        <ProjectsOverview projects={projects}/>
      </main>
    </div>
  );
};

export default Dashboard;
