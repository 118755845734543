import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient'; // Ensure this path is correct

const KPIValueTable = ({ unitId, kpiIds }) => {
  const [kpiData, setKpiData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKPIs = async () => {
      try {
        setLoading(true);
        let fetchedKpiData = [];

        for (const kpiId of kpiIds) {
          // Fetch KPI name
          const { data: nameData, error: nameError } = await supabase
            .from('req_kpi')
            .select('name')
            .eq('id', kpiId)
            .single();
          
          if (nameError) {
            throw nameError;
          }

          // Fetch latest KPI value
          const { data: valueData, error: valueError } = await supabase
            .from('kpis')
            .select('*')
            .eq('req_kpi_id', kpiId)
            .eq('unit_id', unitId)
            .order('created_at', { ascending: false })
            .limit(1);
          console.log(valueData)
          if (valueError) {
            throw valueError;
          }

          if (nameData && valueData.length > 0) {
            fetchedKpiData.push({ name: nameData.name, value: valueData[0].value/10 });
          }
        }
        console.log("fetchedKpiData", fetchedKpiData)
        setKpiData(fetchedKpiData);
      } catch (error) {
        console.error('Error fetching KPIs:', error);
      } finally {
        setLoading(false);
      }
    };

    if (kpiIds.length > 0 && unitId) {
      fetchKPIs();
    }
  }, [unitId, kpiIds]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h3>Latest KPI Values for Unit {unitId}</h3>
      <table>
        <thead>
          <tr>
            <th>KPI Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {kpiData.map((kpi, index) => (
            <tr key={index}>
              <td>{kpi.name}</td>
              <td>{kpi.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KPIValueTable;