// src/components/Login.js
import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import logoDefault from '../../assets/logo.png';
import logoITS from '../../assets/its-consulting.png';
import '../../App.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase.auth.signInWithPassword({ email: username, password: password });
    if (!error) {
      navigate('/dashboard');
    } else {
      alert(error.message);
    }
  };

  const logo = window.location.hostname === 'its-consulting.pitsolutions.eu' ? logoITS : logoDefault;

  return (
    <div className="login-wrapper">
      <div className="login-logo">
        <img src={logo} alt="Logo" />
      </div>
      <h1>Login</h1>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="username"
          placeholder="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;