// src/components/ProjectDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import HVACUnitList from './HVACUnitList';
import HVACDetails from './HVACDetails'; 
import HVACProjectOverview from './HVACProjectOverview';
import { Link } from 'react-router-dom';
import '../../App.css';


const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUnitId, setSelectedUnitId] = useState(null); // State to keep track of selected unit


  useEffect(() => {
    handleUnitSelect(selectedUnitId);
  }, [selectedUnitId]);

  useEffect(() => {
    fetchProjectDetails();
  }, [projectId]);

  const fetchProjectDetails = async () => {
    setIsLoading(true);
    const { data: projectData, error: projectError } = await supabase
      .from('projects')
      .select('*')
      .eq('id', projectId)
      .single();

    if (!projectError) {
      setProject(projectData);
    } else {
      console.error('Error fetching project details:', projectError);
    }
    setIsLoading(false);
  };

  const handleUnitSelect = (unitId) => {
    setSelectedUnitId(unitId);
  };
  if (isLoading) return <div>Loading...</div>;

  return (
      <div className="project-details-container">
        <h1>{project?.name}</h1>
        <div className="project-layout">
          <aside className="navbar">
            <HVACUnitList projectId={projectId} onUnitSelect={handleUnitSelect} />
            <Link to="/dashboard" className="back-to-dashboard">
              <span className="arrow">← Back to Dashboard </span> 
            </Link>
            <div className="signout-link">
              <Link to="/signout">Sign Out</Link>
            </div>
          </aside>
          <main className="main-content">
        {selectedUnitId ? (
          <HVACDetails unit={selectedUnitId} />
        ) : (
          <HVACProjectOverview projectId={projectId} />
        )}
      </main>
        </div>
      </div>
    );
  };

export default ProjectDetails;
