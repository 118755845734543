import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { FaRegSnowflake } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from "leaflet";
import icon from '../../assets/location.png'; // replace 'path_to_your_icon' with the actual path to your icon
import "leaflet/dist/leaflet.css";
import '../../App.css';


const redIcon = L.icon({
    iconUrl: icon,
    iconSize: [30, 30], // size of the icon, adjust as needed
    iconAnchor: [12, 50], // point of the icon which will correspond to marker's location, adjust as needed
    popupAnchor: [0, -50] // point from which the popup should open relative to the iconAnchor, adjust as needed
  });


const ProjectOverview = ({ projects }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [clientUnits, setClientUnits] = useState({});
    const [activeUnits, setActiveUnits] = useState([]);


    useEffect(() => {
        const fetchUnitData = async () => {
            try {
                // Extract client IDs from projects
                const clientIds = projects.map(project => project.id);

                // Fetch units related to these clients
                let { data: units, error: unitsError } = await supabase
                    .from('units')
                    .select('*')
                    .in('client_id', clientIds);

                if (unitsError) throw unitsError;

                // Determine the timestamp for one hour ago
                const oneHourAgo = new Date(new Date().getTime() - 60 * 60 * 1000).toISOString();

                let activeUnits = [];
                for (let unit of units) {
                    let { data: kpis, error: kpisError } = await supabase
                        .from('kpis')
                        .select('*')
                        .eq('unit_id', unit.id)
                        // Filter KPIs to those created within the last hour
                        .gte('created_at', oneHourAgo);

                    if (kpisError) throw kpisError;

                    // If there are KPIs for the unit from the last hour, consider it active
                    if (kpis.length > 0) {
                        activeUnits.push(unit.id);
                    }
                }

                setActiveUnits(activeUnits);

                let groupedUnits = units.reduce((acc, unit) => {
                    acc[unit.client_id] = [...(acc[unit.client_id] || []), unit];
                    return acc;
                }, {});

                setClientUnits(groupedUnits);
                console.log(groupedUnits);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUnitData();
    }, [projects]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    
    return (
        <div>
            <h2>Project Overview</h2>
            {projects.map(project => (
                <div key={project.id} className="project-overview-container">
                    <h3>{project.clientname}</h3>
                    {clientUnits[project.id] && clientUnits[project.id].map(unit => (
                        <FaRegSnowflake size={30} color={activeUnits.includes(unit.id) ? "green" : "gray"} />                    ))}
                </div>

            ))}
            <MapContainer className="map-container" center={[45.81444, 15.97798]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {projects.map(project => {
                    const units = clientUnits[project.id] || [];
                    const activeUnitsCount = units.filter(unit => activeUnits.includes(unit.id)).length;
                    return units.map(unit => {
                        const location = JSON.parse(unit.location);
                        return (
                        <Marker position={location} icon={redIcon}>
                            <Popup>
                            Client: {project.clientname} <br />
                            Active Units: {activeUnitsCount}
                            </Popup>
                        </Marker>
                        );
                    });
                    })}
            </MapContainer>
        </div>
    );
};

export default ProjectOverview;