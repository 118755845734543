// src/components/HVACDetailsComponent.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import { supabase } from '../../supabaseClient';
import GraphWidget from '../dashboard/GraphWidget';
import KPIValueTable from './KPIValueTable';
import AlarmWidget from './AlarmWidget';
import DeviceOverview from './DeviceOverview'; // Import DeviceOverview

const HVACDetails = ({ unit }) => {

  const [details, setDetails] = useState([]);
  const { unitId } = useParams();

  useEffect(() => {
    const fetchHVACDetails = async () => {
      const { data, error } = await supabase
        .from('HVACDetails')
        .select('*')
        .eq('unit_id', unit);

      if (error) {
        console.error('Error fetching HVAC details:', error);
      } else {
        setDetails(data);
      }
    };

    fetchHVACDetails();
  }, [unit]);

  return (
    <div>
      {details.sort((a, b) => {
        const order = ['DeviceOverview', 'table', 'graph', 'AlarmWidget'];
        return order.indexOf(a.widget) - order.indexOf(b.widget);
      }).map((detail, index) => (
        <div key={index} className="widget-wrapper">
          {detail.widget === 'DeviceOverview' && <DeviceOverview unitId={unit} kpiIds={detail.req_kpi_ids} />}
          {detail.widget === 'table' && <KPIValueTable unitId={unit} kpiIds={detail.req_kpi_ids} />}
          {detail.widget === 'graph' && <GraphWidget unitId={unit} kpiIds={detail.req_kpi_ids} />}
          {detail.widget === 'AlarmWidget' && <AlarmWidget unitId={unit} kpiId={detail.req_kpi_ids} />}
        </div>
      ))}
    </div>
  );
};

export default HVACDetails;