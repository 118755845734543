import React from 'react';
import UnitsOverview from './UnitsOverview';
import AlarmsOverview from './AlarmsOverview';

const HVACProjectOverview = ({ projectId }) => {
    return (
        <div>
            <h2>Project Overview</h2>
                <div key={projectId} className="project-overview-container">
                    <UnitsOverview projectId={projectId} />
                    <AlarmsOverview projectId={projectId} />
                </div>
        </div>
    );
};

export default HVACProjectOverview;