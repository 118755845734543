import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const AlarmWidget = ({ unitId, kpiId }) => {
  const [alarms, setAlarms] = useState([]);
  const [loading, setLoading] = useState(true);

  const alarmDictionary = {
    1: "Attempt to access with wrong password",
    2: "Expansion failure/disconnected OR Inverter expansion failure/disconnected",
    3: "Circuit 2 EXV valve driver: Driver not connected",
    4: "High chilled water temperature",
    5: "High chilled water temperature circuit 1",
    6: "High chilled water temperature circuit 2",
    7: "Room humidity below min. limit",
    8: "Room humidity above max. limit",
    9: "Room temperature below min. limit",
    10: "Room temperature above max. limit",
    11: "Inlet temperature above max. limit",
    12: "Outlet temperature above max. limit",
    13: "LAN interrupted",
    14: "Outdoor temperature sensor failed/disconnected",
    15: "Hot water temperature sensor failed/disconnected",
    16: "Water in temperature sensor failed/disconnected",
    17: "Delivery air temperature sensor failed/disconnected",
    18: "Room humidity sensor failed/disconnected",
    19: "Room temperature sensor failed/disconnected",
    20: "Inlet temperature 1 failed/disconnected",
    21: "Inlet temperature 2 failed/disconnected",
    22: "Inlet temperature 3 failed/disconnected",
    23: "Inlet temperature 4 failed/disconnected",
    24: "Outlet temperature 1 failed/disconnected",
    25: "Outlet temperature 2 failed/disconnected",
    26: "Outlet temperature 3 failed/disconnected",
    27: "Outlet temperature 4 failed/disconnected",
    28: "Inlet water 1 probe failed/disconnected",
    29: "Inlet water 2 probe failed/disconnected",
    30: "Door opened",
    31: "RESERVED",
    32: "RESERVED",
    33: "EEPROM failure: replace control board",
    34: "Humidifier: loss of power",
    35: "Humidifier: loss of water",
    36: "Humidifier: high current",
    37: "Clogged filters clean or replace filter element",
    38: "Loss of main power",
    39: "Heaters overheating or heaters contactor failed",
    40: "EEV1 on alarm",
    41: "EEV2 on alarm",
    42: "EEV on alarm",
    43: "Circuit 2 low evaporating pressure",
    44: "Circuit 1 low evaporating pressure",
    45: "Low evaporating pressure",
    46: "Circuit 2 high condensing pressure",
    47: "Circuit 1 high condensing pressure",
    48: "High condensing pressure",
    49: "Electronic fan error",
    50: "Loss of air flow check fan/switch",
    51: "Fan 1 disconnected",
    52: "Fan 2 disconnected",
    53: "Fan 3 disconnected",
    54: "Fan 4 disconnected",
    55: "Dewpoint error",
    56: "Flood alarm check water connections",
    57: "Presence of smoke/fire",
    58: "Flood alarm or smoke/fire alarm",
    59: "Wrong power phases sequence: please change-over 2 phases",
    60: "Differential pressure sensor failed/disconnected",
    61: "Inverter error",
    62: "Envelope alarm",
    63: "Envelope inconsistent",
    64: "Delta pressure discharge-suction inverter compressor",
    65: "Humidifier offline",
    66: "Alarm humidifier cylinder",
    67: "Alarm humidifier drain",
    68: "Alarm humidifier generic",
    69: "Discharge temperature failed/disconnected",
    70: "Compressor in PROTECTION ZONE",
    71: "Compressor in LOCKING ZONE",
    72: "Loss of charge",
    73: "Remote temperature 1 failed/disconnected",
    74: "Remote temperature 2 failed/disconnected",
    75: "Water out temperature 1 failed/disconnected",
    76: "Water out temperature 2 failed/disconnected",
    77: "High outlet water circuit 1",
    78: "High outlet water circuit 2",
    79: "Serial probe T+H address 129 offline",
    80: "Aux temperature sensor address 129 failure/disconnected",
    81: "Aux humidity sensor address 129 failure/disconnected",
    82: "High pressure prevention"
  }


  useEffect(() => {
    fetchAlarms();
  }, [unitId, kpiId]);

  const fetchAlarms = async () => {
    setLoading(true);
    // Define a startDate for the alarms you wish to fetch
    const startDate = new Date(new Date().setHours(new Date().getHours() - 6)).toISOString();
    console.log("kpiId: ", kpiId)
    try {
      const { data, error } = await supabase
        .from('kpis')
        .select('*')
        .eq('unit_id', unitId)
        .eq('req_kpi_id', kpiId)
      
      if (error) throw error;
      // Process and map data here
      const processedAlarms = processData(data);
      setAlarms(processedAlarms);
    } catch (error) {
      console.error('Error fetching alarms:', error);
    } finally {
      setLoading(false);
    }
  };

  const processData = (data) => {
    // This is where you would process your data and map alarms
    // For example, consolidating multiple occurrences of the same alarm
    // and indicating if the alarm is still open
    // This is a simplified example and may need to be adjusted based on your exact data structure and requirements
    const alarmOccurrences = data.reduce((acc, alarm) => {
      const alarmName = alarmDictionary[alarm.alarmID] || 'Unknown Alarm';
      if (!acc[alarmName]) {
        acc[alarmName] = { ...alarm, count: 1 };
      } else {
        acc[alarmName].count++;
        acc[alarmName].lastOccurred = alarm.created_at;
      }
      return acc;
    }, {});

    return Object.values(alarmOccurrences);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
      <table>
        <thead>
          <tr>
            <th>Alarm Name</th>
            <th>Occurrences</th>
            <th>Last Occurred</th>
          </tr>
        </thead>
        <tbody>
          {alarms.map((alarm, index) => (
            <tr key={index}>
              <td>{alarmDictionary[alarm.value] || 'Unknown'}</td>
              <td>{alarm.count}</td>
              <td>{alarm.lastOccurred ? new Date(alarm.lastOccurred).toLocaleString() : 'Ongoing'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AlarmWidget;